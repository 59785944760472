import {
  Link,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material"
import { LinkStyles, containerStyles } from "./styles"
import ProfileBG from "../../assets/img/home-page-updated.png"
import { useEffect, useState } from "react"
import { PrimaryButton } from "../../components/button"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import UserPlaceholder from "../../assets/img/user-avatar-placeholder.png"
import Game1 from "../../assets/img/Quiz_cardImg.jpeg"
import Game2 from "../../assets/img/Craft_cardImg.jpeg"
import Game3 from "../../assets/img/community-app-send.png"
import { formDataAuthRequest } from "../../services/api-service"
import { setImage, setUserDetails } from "../../models/user/userSlice"
import Calender from "../../components/calender/calender"
import callenderbg from "../../assets/img/callenderbg.png"
import AvatarService from "../../services/image-service"
import { MdEdit } from "react-icons/md"
import ShareIcon from "@mui/icons-material/Share"
import ShareModal from "../../components/modal/share"
import ProfilePic from "../../components/modal/ProfilePic"
import { Helmet } from "react-helmet"
import TabCalenderView from "../../components/tabCalenderView"

interface IBanner {
  image: string
  link: string
  file_type: string
}
const HomePage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const imageService = new AvatarService()
  const gameData = [
    { image: Game1, link: "https://atlas-lms-webapp.azurewebsites.net" },
    { image: Game2, link: "https://atlas-teaching-web-app.vercel.app" },
    { image: Game3, link: "https://atlas-forum.azurewebsites.net/" },
  ]
  const user = useAppSelector((state) => state.user)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [products, setProducts] = useState([])
  const API_URL = import.meta.env.VITE_BASE_URL
  const [shareModalData, setShareModalData] = useState<boolean>(false)
  const [isProfilePicModalOpen, setIsProfilePicModalOpen] = useState(false)
  const [campaginBannerData, setCampaignBannerData] = useState<IBanner>({
    image: "",
    link: "",
    file_type: "",
  })
  const [heroBannerData, setHeroBannerData] = useState<IBanner>({
    image: "",
    link: "",
    file_type: "",
  })

  const handleModalOpen = () => {
    setIsEditModalOpen(true)
  }
  const handleShareModalOpen = () => {
    setShareModalData(true)
  }

  const getUserData = async () => {
    const response = await formDataAuthRequest(user.token).post(
      "/api/getZohoRecord",
      { membership_id: user.membership_id },
    )

    if (response) {
      const childrenDetails = []

      response.data.record.Children_Details.map((child) => {
        const data = {
          Child_Name: child.Child_Name,
          DOB: child.DOB,
          Grade: child.Grade,
          Subjects: child.Subjects,
        }
        childrenDetails.push(data)
      })

      const data = {
        first_name: response.data.record.First_Name,
        last_name: response.data.record.Last_Name,
        record_source: response.data.record.Name,
        mobile: response.data.record.Mobile,
        email: response.data.record.Email,
        district: response.data.record.District,
        student_id: response.data.record.Student_ID,
        children_details: childrenDetails,
      }

      dispatch(setUserDetails({ ...user, userDetails: data }))
      dispatch(setImage({ user_image: response?.data?.image || 0 }))
    }
  }

  const getProductData = async () => {
    const response = await formDataAuthRequest(user.token).get(
      "/api/getProducts",
    )

    if (response) {
      setProducts(response.data.data)
    }
  }
  const fetchData = async () => {
    try {
      const response = await formDataAuthRequest(user.token).get(
        "/api/getCampaigns",
      )
      const banners = await response.data.data
      const withLoginBanner = await banners.filter((item) => item.place == 2)
      const campaignBanner = await banners.filter((item) => item.place == 3)
      setCampaignBannerData(campaignBanner[0])
      setHeroBannerData(withLoginBanner[0])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (user.isLoggedIn) {
      navigate("/home")
    } else {
      navigate("/")
    }
  }, [user.isLoggedIn])

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchData()
    getUserData()
    getProductData()
  }, [])

  // useEffect(() => {
  //   if (user.isLoggedIn) {
  //     getUserData()
  //   }
  // }, [user.isLoggedIn])

  return (
    <>
      <Helmet>
        <title>Atlas World</title>
      </Helmet>
      {isProfilePicModalOpen && (
        <ProfilePic
          isOpen={isProfilePicModalOpen}
          setIsOpen={setIsProfilePicModalOpen}
        />
      )}
      <Container
        sx={{
          ...containerStyles,
          padding: "0 !important",
          backgroundColor: "#FF5600",
        }}
      >
        {heroBannerData && (
          <Box
            onClick={() => {
              if (heroBannerData?.link) {
                window.open(heroBannerData?.link, "_blank")
              }
            }}
          >
            {/* <video
            style={{ width: "100%" }}
            src={HomeHeroVideo}
            autoPlay
            muted
            loop
          /> */}
            {heroBannerData?.file_type == "image" ? (
              <img
                src={`${API_URL}${heroBannerData?.image}`}
                alt="Logo"
                style={{ width: "100%", maxHeight: "100vh", cursor: "pointer" }}
              />
            ) : (
              <video
                style={{ width: "100%", cursor: "pointer" }}
                src={`${API_URL}${heroBannerData?.image}`}
                autoPlay
                muted
                loop
              />
            )}
          </Box>
        )}
      </Container>
      <Box
        sx={{
          background: `url(${ProfileBG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          px: { xs: "16px", sm: "90px" },
          py: "56px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box
              sx={{
                background: "#ffffff",
                // background: "yellow",
                // padding: "5px 5px 5px 5px",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                paddingTop: { xs: "10px", lg: "0px" },
                paddingBottom: { xs: "10px", lg: "0px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  width: "80%",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 700,
                    color: "black",
                    mb: "20px",
                    fontSize: "26px",
                  }}
                >
                  Personal Details
                </Typography>
                <Box sx={{ position: "relative" }}>
                  <Box
                    component="img"
                    height={"200px"}
                    src={
                      user?.user_image == 0
                        ? UserPlaceholder
                        : imageService.getAvatar(user.user_image - 1)
                    }
                  ></Box>
                  <Box
                    onClick={() => {
                      setIsProfilePicModalOpen(true)
                    }}
                    component="button"
                    sx={{
                      width: "35px",
                      height: "35px",
                      backgroundColor: "#EFECE8",
                      border: "3px solid #ffffff",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      bottom: "0",
                      cursor: "pointer",
                      right: "18px",
                      "& svg": {
                        width: "16px",
                        height: "16px",
                      },
                    }}
                  >
                    <MdEdit />
                  </Box>
                </Box>
                <Tooltip
                  title={
                    (user.userDetails?.first_name || "") +
                    " " +
                    (user.userDetails?.last_name || "")
                  }
                  arrow
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 700,
                      color: "black",
                      mt: "20px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      textAlign: "-webkit-center",
                      width: "200px",
                    }}
                  >
                    {(user.userDetails?.first_name || "") +
                      " " +
                      (user.userDetails?.last_name || "")}
                  </Typography>
                </Tooltip>
                <Typography>
                  Member ID: {user?.userDetails?.student_id}
                </Typography>
                <PrimaryButton
                  sx={{
                    height: "70px",
                    fontWeight: 400,
                    fontSize: "22px",
                    mt: "40px",
                    mb: "10px",
                  }}
                  onClick={() => navigate("/profile-details")}
                >
                  View Profile
                </PrimaryButton>
                <Button
                  sx={{ color: "black" }}
                  startIcon={<ShareIcon />}
                  onClick={() => {
                    handleShareModalOpen()
                  }}
                >
                  Share ATLAS World
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                marginTop: { xs: "20px", lg: "0px" },
              }}
            >
              {campaginBannerData?.file_type == "image" ? (
                <img
                  onClick={() => {
                    if (campaginBannerData?.link) {
                      window.open(campaginBannerData?.link, "_blank")
                    }
                  }}
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  //bannerTwo
                  src={`${API_URL}${campaginBannerData?.image}`}
                />
              ) : (
                <video
                  style={{
                    borderRadius: "10px",
                    width: "100%",
                    objectFit: "cover",
                    cursor: "pointer",
                  }}
                  src={`${API_URL}${campaginBannerData?.image}`}
                  autoPlay
                  muted
                  loop
                />
              )}

              <Box
                sx={{
                  background: "white",
                  borderRadius: "10px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  padding: "50px 0px 50px 0px",
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 700,
                    color: "#FF5600",
                  }}
                >
                  Special benefits coming soon
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            marginTop: "80px",
            marginBottom: "10px",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box sx={{ width: "100%" }}>
              <img
                src={Game1}
                alt="image"
                style={{
                  width: "inherit",
                  height: "inherit",
                  borderRadius: "16px",
                  cursor:"pointer"
                }}
                onClick={() => {
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.atlas.quizmaster",
                    "_blank",
                  )
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box sx={{ width: "100%" }}>
              <img
                src={Game2}
                alt="image"
                style={{
                  width: "inherit",
                  height: "inherit",
                  borderRadius: "16px",
                  cursor:"pointer"
                }}
                onClick={() => {
                  window.open(
                    "https://atlasplaypalz.lk/genius-zone/",
                    "_blank",
                  )
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Box sx={{ width: "100%" }}>
              <img
                src={Game3}
                alt="image"
                style={{
                  width: "inherit",
                  height: "inherit",
                  borderRadius: "16px",
                  cursor:"pointer"
                }}
                onClick={() => {}}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Container
        sx={{
          ...containerStyles,
          background: `url(${callenderbg})`,
          backgroundSize: "100% 25%", // Adjust the height (50px in this example)
          backgroundRepeat: "no-repeat",
          px: { xs: "16px", sm: "90px" },
          py: "56px",
        }}
      >
        <TabCalenderView />
        {/* <Container
          sx={{
            borderRadius: "20px",
            py: "43px !important",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              textTransform: "uppercase",
              textAlign: "left",
              fontSize: "40px",
              width: "100%",
              lineHeight: "46px",
              fontWeight: 700,
              color: "#FF5600",
              mb: "50px",
            }}
          >
            Shop Our Products
          </Typography>
          <Grid container>
            {products.length > 0 &&
              products.map((product, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    key={index}
                    sx={{
                      border: "1px solid black",
                    }}
                  >
                    <Grid
                      container
                      sx={{
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={6}>
                        <Box
                          component="img"
                          src={import.meta.env.VITE_BASE_URL + product.image}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{
                            fontSize: "22px",
                            color: "#FF5600",
                            fontWeight: 700,
                            mb: "20px",
                          }}
                        >
                          {product.title}
                        </Typography>
                        <PrimaryButton
                          sx={{
                            height: "39px",
                            width: "106px",
                            borderRadius: "80px",
                            fontSize: "14px",
                          }}
                        >
                          <Link
                            sx={LinkStyles}
                            href={product.link}
                            target="_blank"
                          >
                            {" "}
                            Buy Now{" "}
                          </Link>
                        </PrimaryButton>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
          </Grid>
        </Container> */}
      </Container>

      <Box
        sx={{ marginTop: "20px", padding: { xs: "0px 16px", lg: "0px 90px" } }}
      >
        <Typography
          sx={{
            textTransform: "uppercase",
            textAlign: "left",
            fontSize: "40px",
            width: "100%",
            lineHeight: "46px",
            fontWeight: 700,
            color: "#FF5600",
            mb: "50px",
          }}
        >
          Shop Our Products
        </Typography>

        <Grid container sx={{ marginBottom: "40px" }}>
          {products.length > 0 &&
            products.map((product, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                key={index}
                sx={{ border: "1px solid", padding: "5px" }}
              >
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box
                    component="img"
                    src={import.meta.env.VITE_BASE_URL + product.image}
                    sx={{ width: "50%" }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "22px",
                        color: "#FF5600",
                        fontWeight: 700,
                        mb: "20px",
                      }}
                    >
                      {product.title}
                    </Typography>
                    <PrimaryButton
                      sx={{
                        height: "39px",
                        width: "106px",
                        borderRadius: "80px",
                        fontSize: "14px",
                      }}
                    >
                      <Link sx={LinkStyles} href={product.link} target="_blank">
                        {" "}
                        Buy Now{" "}
                      </Link>
                    </PrimaryButton>
                  </Box>
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>

      <ShareModal data={shareModalData} setData={setShareModalData} />
    </>
  )
}

export default HomePage
