import {
  Box,
  CircularProgress,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material"
import banner from "../../assets/img/Quiz_cardImg.jpeg"
import andoridBtn from "../../assets/img/androidBtn.png"
import React, { useState } from "react"
import { Helmet } from "react-helmet"

const BASE_URL = import.meta.env.VITE_BASE_URL
const AppPage = () => {
  const handleDownload = () => {
    try {
      const iframe = document.createElement("iframe")
      iframe.style.display = "none"
      iframe.src = `${BASE_URL}api/download/file?path=public/quizmaster.apk`
      document.body.appendChild(iframe)

      iframe.onload = () => {
        setTimeout(() => {
          document.body.removeChild(iframe)
        }, 500)
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <div>
      {/* <Container
        sx={{
          width: "100%",
          maxWidth: "unset !important",
          padding: { xs: "20px", md: "100px" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#FF5600",
        }}
      >
        <Box sx={{ width: { xs: "80%", md: "30%" } }}>
          <img
            src={banner}
            alt="Logo"
            style={{ width: "100%", borderRadius: "20px", cursor: "pointer" }}
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.atlas.quizmaster",
                "_blank",
              )
            }}
          />
        </Box>
      </Container> */}
      <Grid
        container
        sx={{
          background:
            "linear-gradient(183.57deg, rgba(255, 155, 78, 0.9) 2.93%, rgba(252, 183, 85, 0.9) 33.26%, rgba(248, 214, 93, 0.9) 55.9%, rgba(255, 255, 255, 0.9) 89.85%)",
          width: "auto",
          padding: { xs: "20px", md: "100px" },
          alignItems: "center",
        }}
        spacing={2}
      >
        <Grid item xs={12} md={5} sx={{ justifyContent: "center" }}>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img
              src={banner}
              alt="Logo"
              style={{ width: "70%", borderRadius: "20px", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.atlas.quizmaster",
                  "_blank",
                )
              }}
            />
          </div>
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: 700,
              color: "#571D00",
              paddingTop: "20px",
              textAlign: "center",
            }}
          >
            Download now and let the learning adventure begin!
          </Typography>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <img
              src={andoridBtn}
              alt="Andorid Button"
              style={{ width: "50%", cursor: "pointer", marginTop: "30px" }}
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.atlas.quizmaster",
                  "_blank",
                )
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={7}>
          <Typography
            sx={{
              fontSize: "40px",
              textAlign: "left",
              fontWeight: 700,
              color: "#571D00",
            }}
          >
            Make learning fun with
          </Typography>
          <Typography
            style={{
              fontSize: "40px",
              textAlign: "left",
              fontWeight: 700,
              color: "#571D00",
            }}
          >
            Quiz Master!
          </Typography>
          <Typography
            sx={{
              fontSize: "22px",
              textAlign: "left",
              fontWeight: 300,
              color: "#571D00",
              paddingTop: "20px",
            }}
          >
            Atlas World introduces a new self-learning app designed to help your
            child learn smarter.
          </Typography>

          <Typography
            sx={{
              fontSize: "22px",
              textAlign: "left",
              fontWeight: 300,
              color: "#571D00",
              paddingTop: "20px",
            }}
          >
            Our app offers:
          </Typography>
          <List
            sx={{
              listStyleType: "disc",
              pl: 4,
              color: "#571D00",
              fontSize: "22px",
              fontWeight: 300,
            }}
          >
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Personalized learning experiences tailored to your child's learning style (audio, visual, kinesthetic)" />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Practice scholarship exam papers" />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Instant feedback and performance tracking" />
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              <ListItemText primary="Content is available in all three languages (Sinhala, English and Tamil)" />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </div>
  )
}

export default AppPage
