import { Box, Button, Container, Grid, Typography } from "@mui/material"
import HeaderBg from "../../assets/img/header-bg.png"
import Logo from "../../assets/img/atlas-world-logo.png"
import { ContainerStyles, LinkStyles } from "./styles"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { resetUser } from "../../models/user/userSlice"

const Header = () => {
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const user = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()

  const handleLoginClick = () => {
    const loginSection = document.getElementById('Login');
    const hasHash = window.location.hash === '#Login';

    if(hasHash){
      if (loginSection) {
        loginSection.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  };

  useEffect(() => {
    handleLoginClick()
  }, [])
  

  return (
    <Container sx={ContainerStyles}>
      <Grid
        container
        sx={{
          gap: "7px",
          alignItems: "center",
        }}
      >
        <Grid item>
          <Box
            component="img"
            sx={{
              height: 97,
              maxHeight: { xs: 97, md: 97 },
            }}
            alt="ATLAS World Logo"
            src={Logo}
            onClick={() => navigate("/")}
          />
        </Grid>
        <Grid
          item
          sx={{
            display: { xs: "flex", md: "none" },
            height: "40px",
            justifyContent: "flex-end",
            flex: "auto",
          }}
        >
          <Button
            sx={{
              width: "40px",
              display: "flex",
              flexDirection: "column",
              position: "relative",
              padding: 0,
              justifyContent: "space-evenly",
            }}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Box
              sx={{
                background: "#FF5600",
                height: "5px",
                width: "40px",
                position: menuOpen ? "absolute" : "relative",
                rotate: menuOpen ? "45deg" : "0deg",
                borderRadius: "5px",
                transition: "all 500ms ease",
              }}
            ></Box>
            <Box
              sx={{
                background: "#FF5600",
                height: "5px",
                width: "40px",
                opacity: menuOpen ? "0" : "1",
                position: menuOpen ? "absolute" : "relative",
                borderRadius: "5px",
                transition: "all 500ms ease",
              }}
            ></Box>
            <Box
              sx={{
                background: "#FF5600",
                height: "5px",
                width: "40px",
                rotate: menuOpen ? "-45deg" : "-0deg",
                position: menuOpen ? "absolute" : "relative",
                borderRadius: "5px",
                transition: "all 500ms ease",
              }}
            ></Box>
          </Button>
        </Grid>
        <Grid
          item
          sx={{
            flex: "auto",
            display: "flex",
            position: { xs: "absolute", md: "relative" },
            overflow: "hidden",
            width: { xs: "100%", md: "auto" },
            top: { xs: 111, md: "unset" },
            left: { xs: 0, md: "unset" },
            visibility: { xs: menuOpen ? "visible" : "hidden", md: "visible" },
            opacity: { xs: menuOpen ? "1" : "0", md: "1" },
            height: { xs: menuOpen ? "fit-content" : 0, md: 111 },
            paddingTop: { xs: menuOpen ? "20px" : "0", md: 0 },
            paddingBottom: { xs: menuOpen ? "20px" : "0", md: 0 },
            backgroundSize: "cover !important",
            background: { xs: "#ffffff", md: `url(${HeaderBg})` },
            transition: "all 500ms ease",
            alignItems: "center",
            zIndex:100,
          }}
        >
          <Grid
            container
            sx={{
              flexWrap: "nowrap",
              height: { xs: "fit-content", md: "unset" },
              gap: { xs: "10px", md: "52px" },
              paddingLeft: { xs: "16px", sm: "90px", md: "75px", lg: "120px" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", md: "center" },
              flexDirection: { xs: "column", md: "row" },
              zIndex: "99"
            }}
          >
            <Container
              sx={{
                display: "flex",
                flexDirection: { xs: "column",  md: "row" },
              }}>
              <Grid item>
                <Typography sx={LinkStyles} onClick={() => {
                  navigate("/")
                  setMenuOpen(!menuOpen)
                  }}>
                  Home
                </Typography>
              </Grid>
              <Grid item>
              <Typography sx={LinkStyles}>
                  <a href="https://atlaslearn.lk/" target="_blank">
                    ATLAS Learn
                  </a>
                </Typography>
                
              </Grid>
              <Grid item>
              <Typography sx={LinkStyles} onClick={() => {
                  navigate("/quizMaster")
                  setMenuOpen(!menuOpen)
                  }}>
                    Quiz Master
                </Typography>
              </Grid>
              <Grid item>
              <Typography sx={LinkStyles}>
                  <a href="https://www.atlas.lk/myshop/" target="_blank">
                    Myshop
                  </a>
                </Typography>
              </Grid>
            </Container>
            {user.isLoggedIn ? (
            <div>
              <Button
                onClick={() => {
                  dispatch(resetUser())
                }}
                sx={{
                  marginRight: "100px",
                  width: {xs: "100px", md: "139px"},
                  height: {xs: "35px", md: "45px"},
                  background: "#ea5104",
                  border: "5px solid #e65005",
                  color: "white",
                  fontSize: {xs: "14px", md: "16px"},
                  borderRadius: "30px",
                  fontWeight: 700,
                  cursor: "pointer",
                }}>
                Logout
              </Button>
            </div>
            ) : (
              <a href="/#Login">
              <Button
                sx={{
                  marginRight: "100px",
                  width: {xs: "100px", md: "139px"},
                  height: {xs: "35px", md: "45px"},
                  background: "#ea5104",
                  border: "5px solid #e65005",
                  color: "white",
                  fontSize: {xs: "14px", md: "16px"},
                  borderRadius: "30px",
                  fontWeight: 700,
                  cursor: "pointer",
                }}>
                LogIn
              </Button>
              </a>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default Header